<main class="main overview">
  <div class="overview__container">
    <ag-grid-angular
      class="ag-theme-quartz"
      [rowData]="rowData"
      [sideBar]="sideBar"
      [pivotMode]="false"

      [defaultColDef]="defaultColDef"
      [autoGroupColumnDef]="autoGroupColumnDef"
      [columnDefs]="colDefs"
      (gridReady)="onGridReady($event)"
    />
  </div>
</main>

