import { Component, inject, OnInit } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LeadDetailsComponent } from '../../../pages/lead-details/lead-details.component';

@Component({
    selector: 'app-lead-details-dialog',
    imports: [LeadDetailsComponent],
    templateUrl: './lead-details-dialog.component.html',
    styleUrl: './lead-details-dialog.component.scss'
})
export class LeadDetailsDialogComponent implements OnInit {
  private dialogService: DialogService = inject(DialogService);
  private dynamicDialogRef: DynamicDialogRef = inject(DynamicDialogRef);
  public data: any;

  constructor() {
    this.data = this.dialogService.getInstance(
      this.dynamicDialogRef,
    ).data;
  }

  ngOnInit() {}
}
