@if (crux) {
  <div class="crux-wrap">
    <!-- Historical metrics -->
    <div class="crux">
      <div [class]="['crux-item', crux.historical?.cls]">CLS</div>
      <div [class]="['crux-item', crux.historical?.fcp]">FCP</div>
      <div [class]="['crux-item', crux.historical?.lcp]">LCP</div>
      <span>Historical</span>
    </div>
    <!-- Realtime metrics -->
    <div class="crux">
      <div [class]="['crux-item', crux.realtime?.hp?.cls]">CLS</div>
      <div [class]="['crux-item', crux.realtime?.hp?.fcp]">FCP</div>
      <div [class]="['crux-item', crux.realtime?.hp?.lcp]">LCP</div>
      <span>Realtime HP</span>
    </div>
  </div>
}
