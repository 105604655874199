import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Badge } from 'primeng/badge';
import { Popover } from 'primeng/popover';
import { TableModule } from 'primeng/table';

@Component({
  selector: 'app-overview-forms-renderer',
  imports: [Badge, Popover, TableModule],
  templateUrl: './overview-forms-renderer.component.html',
  styleUrl: './overview-forms-renderer.component.scss',
})
export class OverviewFormsRendererComponent implements ICellRendererAngularComp {
  protected params: any = {};
  protected forms: any = [];
  constructor() {}
  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.refresh(params);
  }
  refresh(params: any): boolean {
    this.forms = params?.data?.forms;
    return true;
  }
}
