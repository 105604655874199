import { Component, inject } from '@angular/core';
import { AgGridAngular } from '@ag-grid-community/angular';
import { ColDef, GridApi, GridReadyEvent, ICellRendererParams, SideBarDef } from '@ag-grid-community/core';

import { ModuleRegistry } from '@ag-grid-community/core';
import { SideBarModule } from '@ag-grid-enterprise/side-bar';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { SparklinesModule } from '@ag-grid-enterprise/sparklines';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ViewCellRenderComponent } from '../../components/ui/grid/view-cell-render/view-cell-render.component';
import { CruxRendererComponent } from '../../components/ui/grid/crux-renderer/crux-renderer.component';
import {
  OverviewFormsRendererComponent
} from '../../components/ui/grid/overview-forms-renderer/overview-forms-renderer.component';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';

ModuleRegistry.registerModules([ SideBarModule, FiltersToolPanelModule, ColumnsToolPanelModule, ClientSideRowModelModule, SparklinesModule, SetFilterModule ]);

const formatValue = (value: string) => {
  return value
    .split('_')
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

const kpiSparklinesTooltipRenderer = (params: any) => {
  const { yValue, xValue } = params;
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const [month, year] = xValue.toString().split('_');
  return {
    title: `${months[parseInt(month)]} ${year}`,
    content: yValue
  };
}

const kpiSparklinesDataFormatter = (data: any) => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();
  return data.filter((item: any) => {
    const [month, year] = item.period.split('_').map(Number);
    const itemTotalMonths = year * 12 + month;
    const currentTotalMonths = currentYear * 12 + currentMonth;
    const monthsDiff = currentTotalMonths - itemTotalMonths;
    return monthsDiff >= 0 && monthsDiff < 12;
  });
}

@Component({
    selector: 'app-overview',
    imports: [AgGridAngular],
    templateUrl: './overview.component.html',
    styleUrl: './overview.component.scss'
})
export class OverviewComponent {
  private http = inject(HttpClient);

  public gridApi: GridApi | undefined;
  public sideBar: SideBarDef = {
    // toolPanels: ['columns'],
    // hiddenByDefault: false,
    // position: 'right'
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
        }
      }
    ]
  };

  public defaultColDef: ColDef = {
    // flex: 0,
    // minWidth: 100,
    // enableValue: true,
    // enableRowGroup: true,
    enablePivot: false,
    // filter: true,

    sortable: true,
    resizable: true,
    editable: false,
    filter: true,
    suppressHeaderMenuButton: true,
    menuTabs: [
      'filterMenuTab',
    ],
    filterParams: {
      buttons: ['reset'],
      maxNumConditions: 1,
      refreshValuesOnOpen: true,
    },
  };

  // https://management-tools.comradeserver.com/overview

  public autoGroupColumnDef: ColDef = {
    minWidth: 200,
  };


  rowData = [];

  // Column Definitions: Defines the columns to be displayed.
  colDefs: ColDef[] = [
    {
      field: 'details',
      headerName: 'Details',
      maxWidth: 80,
      cellRenderer: ViewCellRenderComponent,
      cellRendererParams: {
        isOverview: true,
      },
      suppressHeaderMenuButton: true,
      filter: false,
      pinned: 'left',
    },
    {
      field: 'abbr',
      headerName: 'Abbr',
      filter: 'agTextColumnFilter',
      pinned: 'left',
      maxWidth: 110
    },
    {
      field: 'client_name',
      headerName: 'Client Name',
      filter: 'agTextColumnFilter',
    },
    // {
    //   field: 'projects',
    //   headerName: 'Projects',
    //   filter: 'agSetColumnFilter',
    //   cellRenderer: OverviewProjectsRendererComponent,
    // },
    {
      field: 'url',
      headerName: 'Website',
      filter: 'agTextColumnFilter',
      cellRenderer: (params: ICellRendererParams) => {
        return params.value? `<a class="overview-cell-link" href="https://${params.value}" target="_blank">${params.value}</a>` : ''
      }
    },
    {
      field: 'industry',
      headerName: 'Industry',
      filter: 'agSetColumnFilter',
      valueFormatter: (params: any) => formatValue(params.value),
      filterParams: {
        valueFormatter: (params: any) => formatValue(params.value)
      }
    },
    {
      field: 'forms',
      headerName: 'Problem Forms',
      cellRenderer: OverviewFormsRendererComponent,
      filter: 'agSetColumnFilter',
      maxWidth: 160,
      valueFormatter: (params: any) => {
        if (params?.value?.problems && params?.value?.problems.length > 0) {
          return 'With Problems';
        }
        return 'Without Problems';
      },
    },
    {
      field: 'crux',
      headerName: 'CWV',
      cellRenderer: CruxRendererComponent,
      filter: 'agSetColumnFilter',
      valueFormatter: (params: any) => {
        const { lcp, fcp, cls } = params.value;
        if (lcp === 'good' && fcp === 'good' && cls === 'good') {
          return 'Good';
        } else if (lcp === 'poor' || fcp === 'poor' || cls === 'poor') {
          return 'Poor';
        } else if (lcp === 'unknown' || fcp === 'unknown' || cls === 'unknown') {
          return 'Unknown';
        }
        return 'Need Improvements';
      },
    },
    {
      field: 'kpi.total_traffic.m',
      headerName: 'Total Traffic',
      cellRenderer: 'agSparklineCellRenderer',
      filter: false,
      cellRendererParams: {
        sparklineOptions: {
          type: "column",
          xKey: "period",
          yKey: "value",
          fill: '#FF9894',
          tooltip: {
            renderer: kpiSparklinesTooltipRenderer
          }
        },
      },
      valueGetter: (params: any) => {
        const data = params?.data?.kpi?.total_traffic?.m;
        if (!data) {
          return [];
        }
        return kpiSparklinesDataFormatter(data);
      },
    },
    {
      field: 'kpi.total_unique_conversions.m',
      headerName: 'Total Unique Conversions',
      cellRenderer: 'agSparklineCellRenderer',
      filter: false,
      cellRendererParams: {
        sparklineOptions: {
          type: "column",
          xKey: "period",
          yKey: "value",
          fill: '#7986FF',
          // label: {
          //   enabled: true // show column labels
          // },
          // strokeWidth: 1,
          // highlightStyle: {
          //   item: {
          //     stroke: '#b5ec7c', // sets the highlighted column stroke
          //     strokeWidth: 2, // sets the highlighted column stroke width
          //   }
          // },
          tooltip: {
            renderer: kpiSparklinesTooltipRenderer
          }
        },
      },
      valueGetter: (params: any) => {
        const data = params?.data?.kpi?.total_unique_conversions?.m;
        if (!data) {
          return [];
        }
        return kpiSparklinesDataFormatter(data);
      },
    },
    {
      field: 'kpi.total_mqls.m',
      headerName: 'Total MQLs',
      cellRenderer: 'agSparklineCellRenderer',
      filter: false,
      cellRendererParams: {
        sparklineOptions: {
          type: "column",
          xKey: "period",
          yKey: "value",
          fill: '#AE9AFD',
          tooltip: {
            renderer: kpiSparklinesTooltipRenderer
          }
        },
      },
      valueGetter: (params: any) => {
        const data = params?.data?.kpi?.total_mqls?.m;
        if (!data) {
          return [];
        }
        return kpiSparklinesDataFormatter(data);
      },
    },
    {
      field: 'am.name',
      headerName: 'AM',
      filter: 'agSetColumnFilter',
    },
    {
      field: 'pm.name',
      headerName: 'PM',
      filter: 'agSetColumnFilter',
    },
  ];

  private getOverview() {
    this.http.get<any>(environment.API_PATH + `/analytics/overview`).subscribe((response) => {
      this.rowData = response;
      this.gridApi?.setGridOption('loading', false);
    });
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridApi?.setGridOption('loading', true);
    this.getOverview();
  }

}
