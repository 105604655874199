<div class="flex align-items-center gap-2 h-full">
  <p-badge [value]="forms.total"
           severity="primary"
           class="flex align-items-center">
  </p-badge>
  @if(forms.problems && forms.problems.length > 0) {
    /
    <p-badge [value]="forms.problems.length"
             severity="danger"
             (click)="op.toggle($event)"
             styleClass="cursor-pointer"
             class="flex align-items-center">
    </p-badge>
  }
</div>

<p-popover #op>
  @if(forms.problems && forms.problems.length > 0) {
    <div class="w-[500px]">
      <p-table [value]="forms.problems"
               [paginator]="true"
               [rows]="5"
               [showCurrentPageReport]="true"
               styleClass="p-datatable-sm"
               [sortField]="'days_since_last_submit'"
               [sortOrder]="-1">
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="application">
              Application
              <p-sortIcon field="application"></p-sortIcon>
            </th>
            <th pSortableColumn="form_id">
              Form ID
              <p-sortIcon field="form_id"></p-sortIcon>
            </th>
            <th pSortableColumn="days_since_last_submit">
              Days Since Last Submit
              <p-sortIcon field="days_since_last_submit"></p-sortIcon>
            </th>
            <th pSortableColumn="average_days_between_submissions">
              Average Days Between Submissions
              <p-sortIcon field="average_days_between_submissions"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr>
            <td>{{ item.application }}</td>
            <td>{{ item.form_id }}</td>
            <td>{{ item.days_since_last_submit }}</td>
            <td>{{ item.average_days_between_submissions }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  }
</p-popover>
