import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';

@Component({
  selector: 'app-crux-renderer',
  imports: [],
  templateUrl: './crux-renderer.component.html',
  styleUrl: './crux-renderer.component.scss'
})
export class CruxRendererComponent implements ICellRendererAngularComp {
  protected params: any = {};
  protected crux: any = {};
  constructor() { }
  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.refresh(params);
  }
  refresh(params: any): boolean {
    this.crux = params?.data?.crux;
    return true;
  }
}
