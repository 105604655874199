import { Component, inject, Input, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { DatePipe, JsonPipe, KeyValuePipe, Location } from '@angular/common';
import { ButtonComponent } from '../../components/ui/button/button.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../../services/storage.service';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-lead-details',
    imports: [
        RouterLink,
        ButtonComponent,
        InputNumberModule,
        KeyValuePipe,
        DatePipe,
        JsonPipe,
    ],
    templateUrl: './lead-details.component.html',
    styleUrl: './lead-details.component.scss'
})
export class LeadDetailsComponent implements OnInit {
  private http = inject(HttpClient);
  private router = inject(Router);
  private storageService = inject(StorageService);
  @Input() recordId!: string;
  @Input() contact_id!: string;
  public data: any;
  private devIndex = 0;
  public manualQualification: any = 'lead';
  public activityActiveIndex: number = 0;
  public automateData: any = {};
  public pagination: number = 0;
  public rawData: any = {
    location_url:
      'https://apexwindowwerks.com/services/patio-sliding-door-repair/',
    'wpforms[fields][5]': 'Mark Gaye Lihua',
  };
  public automate_data: any = {
    location_url:
      'https://apexwindowwerks.com/services/patio-sliding-door-repair/',
    'wpforms[fields][5]': 'Mark Gaye Lihua',
  };
  public tech_data: any = {};
  public cookie_data: any = {};
  public userData: any = {};
  public servicesUserData: any = {};
  public otherUserData: any = {};
  public readonly userGeneralInfo: any = [
    {
      key: 'name',
      viewValue: 'Name',
    },
    {
      key: 'phone',
      viewValue: 'Phone',
    },
    {
      key: 'email',
      viewValue: 'Email',
    },
    {
      key: 'service',
      viewValue: 'Service',
    },
    {
      key: 'location',
      viewValue: 'Location',
    },
  ];
  public readonly userServicesInfo: any = [];
  public readonly userOtherInfo: any = [];

  public activities: any[] = [];

  constructor(private location: Location) {}
  ngOnInit() {
    const user_id: any = this.router.url.split('/').pop();
    this.getUserData(this.contact_id);
  }

  private getUserData(user_id: string) {
    this.http
      .get(environment.API_PATH + `/contacts/${user_id}`)
      .subscribe((response: any) => {
        this.userData = response;
        this.showDetails(this.activities[0], 0);
      });

    this.http
      .get(environment.API_PATH + `/contacts/${user_id}/activities`)
      .subscribe((response: any) => {
        this.activities = response;
        this.showDetails(this.activities[0], 0);
      });
  }

  showDetails(activity: any, index: number) {
    this.rawData = activity?.raw_data;
    this.automate_data = activity?.automate_data;
    this.cookie_data = activity?.cookie_data;
    this.tech_data = activity?.tech_data;
    this.activityActiveIndex = index;
  }
  nextLead() {
    this.pagination++;
    this.http
      .post(environment.API_PATH + '/contacts/by-pagination', {
        skip: this.pagination,
        platform_id: this.storageService.$activePlatform.value,
      })
      .subscribe((response: any) => {
        this.getUserData(response[0]._id);
        this.showDetails(this.activities[0], 0);
        this.location.replaceState('/lead-center/' + response[0]._id);
      });
  }

  prevLead() {
    if (this.pagination !== 0) {
      this.pagination--;
      this.http
        .post(environment.API_PATH + '/activities/by-pagination', {
          skip: this.pagination,
          platform_id: this.storageService.$activePlatform.value,
        })
        .subscribe((response: any) => {
          this.getUserData(response.contact._id);
          this.showDetails(this.activities[0], 0);
          this.location.replaceState('/lead-center/' + response.contact._id);
        });
    }
  }
}
