<main class="main">

  <div class="lead-details-top">
    <div class="breadcrumbs">
      <a routerLink="/lead-center">Lead Center</a>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M6 12L10 8L6 4" stroke="#7485A6" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      {{userData?.name || '-'}}
    </div>

    <div class="lead-details-top__nav">
      <app-button
        [disabled]="pagination === 0"
        label="Previous"
        [outlined]="true"
        (click)="prevLead()"
        [style]="{'width': '100%', 'border-color': '#7986FF'}"
      />
      <app-button
        label="Next"
        [outlined]="true"
        (click)="nextLead()"
        [style]="{'width': '100%', 'border-color': '#7986FF'}"
      />
    </div>
  </div>
  <br>
  <div class="lead-details-grid">
    <div class="lead-details-grid__item">
      <h2>User info <span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21" stroke="#7986FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z" stroke="#7986FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg></span></h2>
      <div class="lead-details-grid__item-section">
        <div class="lead-details-grid__item-section-heading">General</div>
          @for (item of userGeneralInfo; track item.key) {
            <div class="user-info__item">
              <div class="lead-details-grid__item-section-label">{{item.viewValue}}</div>
              <div class="lead-details-grid__item-section-value">{{userData[item.key] || '-'}}</div>
            </div>
          }
      </div>
    </div>
    <div class="lead-details-grid__item lead-details-grid__item--journey">
      <h2>User journey <span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M18 21C19.6569 21 21 19.6569 21 18C21 16.3431 19.6569 15 18 15C16.3431 15 15 16.3431 15 18C15 19.6569 16.3431 21 18 21Z" stroke="#7986FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M6 9C7.65685 9 9 7.65685 9 6C9 4.34315 7.65685 3 6 3C4.34315 3 3 4.34315 3 6C3 7.65685 4.34315 9 6 9Z" stroke="#7986FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13 6H16C16.5304 6 17.0391 6.21071 17.4142 6.58579C17.7893 6.96086 18 7.46957 18 8V15" stroke="#7986FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M6 9V21" stroke="#7986FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg></span></h2>

      @for (activity of activities; track activity._id; let i = $index) {
        <div class="user-journey-item" [class.user-journey-item--active]="activityActiveIndex === i">
          <div class="user-journey-item__content">
            <div class="user-journey-item__icon">
              @if (activity.type === 'form') {
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M13 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V9L13 2Z" stroke="#7986FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M13 2V9H20" stroke="#7986FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              } @else if (activity.type === 'call') {
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="phone"><path id="Vector" d="M22 16.92V19.92C22.0011 20.1985 21.9441 20.4741 21.8325 20.7293C21.7209 20.9845 21.5573 21.2136 21.3521 21.4018C21.1468 21.5901 20.9046 21.7335 20.6407 21.8227C20.3769 21.9119 20.0974 21.945 19.82 21.92C16.7428 21.5856 13.787 20.5341 11.19 18.85C8.77382 17.3146 6.72533 15.2661 5.18999 12.85C3.49997 10.2412 2.44824 7.27097 2.11999 4.17997C2.095 3.90344 2.12787 3.62474 2.21649 3.3616C2.30512 3.09846 2.44756 2.85666 2.63476 2.6516C2.82196 2.44653 3.0498 2.28268 3.30379 2.1705C3.55777 2.05831 3.83233 2.00024 4.10999 1.99997H7.10999C7.5953 1.9952 8.06579 2.16705 8.43376 2.48351C8.80173 2.79996 9.04207 3.23942 9.10999 3.71997C9.23662 4.68004 9.47144 5.6227 9.80999 6.52997C9.94454 6.8879 9.97366 7.27689 9.8939 7.65086C9.81415 8.02482 9.62886 8.36809 9.35999 8.63998L8.08999 9.90997C9.51355 12.4135 11.5864 14.4864 14.09 15.91L15.36 14.64C15.6319 14.3711 15.9751 14.1858 16.3491 14.1061C16.7231 14.0263 17.1121 14.0554 17.47 14.19C18.3773 14.5285 19.3199 14.7634 20.28 14.89C20.7658 14.9585 21.2094 15.2032 21.5265 15.5775C21.8437 15.9518 22.0122 16.4296 22 16.92Z" stroke="#7986FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></g>
                </svg>
              } @else {
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="eye"><path id="Vector" d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z" stroke="#7986FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path id="Vector_2" d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="#7986FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></g>
                </svg>
              }
            </div>
            <div>
              <div class="user-journey-item__date">{{activity.created_date | date:'medium' }}</div>
              <div class="user-journey-item__url">{{activity.automate_data.source}}</div>
              <div class="user-journey-item__source">{{activity.type}}</div>
              <button class="user-journey-item__details-btn" (click)="showDetails(activity, i)">See details <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M4.1665 10H15.8332M15.8332 10L9.99984 4.16669M15.8332 10L9.99984 15.8334" stroke="#7986FF" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/></svg></span></button>
            </div>
          </div>
        </div>
      }
    </div>

    <div class="lead-details-grid__item" style="max-height: calc(100vh - 219px) ; overflow: scroll;">
      <div class="lead-event-details">
        <h2>Event details <span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#7986FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M12 16V12" stroke="#7986FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M12 8H12.01" stroke="#7986FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg></span></h2>

        <div class="raw-data">
          <h4>Automate Data</h4>
          @for (item of automate_data | keyvalue; track item.key) {
            <div class="raw-data-item">
              <div class="raw-data-item__label">{{item.key}}:</div>
              @if (item.key === 'location_url' || item.key === 'recording' || item.key === 'recording_player') {
                <div class="raw-data-item__value"><a href="{{item.value}}" target="_blank">{{item.value}}</a></div>
              } @else {
                <div class="raw-data-item__value">{{item.value | json}}</div>
              }
            </div>
          }
          <br>
          <h4>Cookie Data</h4>
          @for (item of cookie_data | keyvalue; track item.key) {
            <div class="raw-data-item">
              <div class="raw-data-item__label">{{item.key}}:</div>
              @if (item.key === 'location_url') {
                <div class="raw-data-item__value"><a href="{{item.value}}" target="_blank">{{item.value}}</a></div>
              } @else {
                <div class="raw-data-item__value">{{item.value | json}}</div>
              }
            </div>
          }

          <br>
          <h4>Tech Data</h4>
          @for (item of tech_data | keyvalue; track item.key) {
            <div class="raw-data-item">
              <div class="raw-data-item__label">{{item.key}}:</div>
              @if (item.key === 'location_url') {
                <div class="raw-data-item__value"><a href="{{item.value}}" target="_blank">{{item.value}}</a></div>
              } @else if (item.key === 'transcript') {
                <div class="raw-data-item__value">{{item.value | json}}</div>
              } @else {
                <div class="raw-data-item__value">{{item.value | json}}</div>
              }
            </div>
          }
        </div>

      </div>
    </div>
  </div>
</main>
