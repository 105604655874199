import { Component, inject } from '@angular/core';
import {Router} from '@angular/router';
import { ICellRendererParams } from '@ag-grid-community/core';
import { DialogService } from 'primeng/dynamicdialog';
import { LeadDetailsDialogComponent } from '../../../dialog/lead-details-dialog/lead-details-dialog.component';

@Component({
    selector: 'app-view-cell-render',
    imports: [],
    providers: [DialogService],
    templateUrl: './view-cell-render.component.html',
    styleUrl: './view-cell-render.component.scss'
})
export class ViewCellRenderComponent {
  private params: any;
  public value: string | undefined;
  private dialogService: DialogService = inject(DialogService);

  constructor(
    private router: Router
  ) {

  }
  agInit(params: any): void {
    this.params = params;
    this.value = params.value;
  }
  // "65c35f94a62535888e895ec4"

  refresh(params: ICellRendererParams) {
    this.params = params;
    return true;
  }

  async onClickViewDetails() {
    if (this.params.isOverview) {
      sessionStorage.clear();
      // console.log('this.params', this.params.data);
      localStorage.setItem('platform', this.params.data.platform_id);

      // @ts-ignore
      window.location = '/dashboard';
    } else {
      this.dialogService.open(LeadDetailsDialogComponent, {
        header: '',
        data: this.params.data,
        width: '95vw',
        height: '90vw',
        modal:true,
        closable: true,
        breakpoints: {
          '960px': '75vw',
          '640px': '90vw'
        },
      });
    }
  }
}
